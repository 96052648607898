.home{
    position: relative !important;
}

.about{
    background-image: url("/src/assests/About/fondo.png") !important;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 200px;
}

.services{
    background-image: url("/src/assests/OurServices/fondo.png") !important;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 200px;
}

.contact{
    background-image: url("/src/assests/Contact/fondo.png") !important;
    background-repeat: no-repeat;
    background-size: cover;
}

.service_icon{
    width: 100px;
}

.button{
    width: 100px;
    margin-left: auto;
}

.vision_icon{
    width: 120px;
    margin-top: 30px;
}
.vision{
    color: #4B458C;
    height: 500px;
}
.mision_icon{
    width: 145px;
    margin-top: 30px;
}
.mision{
    color: #4DDAE3;
    height: 500px;
}

.line{
    height: 5px;
    margin: 10px 20px;
}

.background-image{
    max-width: 1600px;
}

.logo{
    max-width: 500px;
    width: 35vw !important;
}

.icons{
    margin-top: 10px;
}


.icon{
    width: 50px;
    display: inline;
    margin: 0px 10px;
}

.map-view{
    max-width: 500px;
    max-height: 500px;
    width: 60vw;
    height: 60vw;
    border-radius: 50%;
}

.services-title{
    background-image: url("/src/assests/OurServices/title_background.png") !important;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: -200px;
    height: 800px;
    width: 100%;
}

section.services-title div.title{
    position: absolute;
    top: 300px;
    padding: 0px 220px !important;
}

.services-icons{
    padding-top: 600px;
}

.footer{
    position: relative;
}
.footer::before {
    content: "";
    position: absolute;
    width: 115%;
    height: 1000px;
    top: 20px;
    left: -90px;
    z-index: -1;
    background-color: #3C52A1;
    border-radius: 10%;
    transform: rotate(-12deg);
  }

  .footer-message{
      width: 440px;
  }

  .figure-2{
      margin-left: 450px !important;
  }
  .spin-img {
    animation: spinLogo 12s linear infinite;
  }
  .spin-center-position {
    top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translate(-50%, -50%); 
  }
  .img-inside {
    width: 22rem;
    height: 22rem;
  }
  .img-outside {
    width: 24rem;
    height: 24rem;
  }

  @keyframes spinLogo {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @media (min-width:1025px){
    .home{
        height: 1080px;
    }
  }


  @media (max-width:1024px){


    .services-title{
        top: -100px;
        height: 500px;
        width: 100%;
    }

    section.services-title div.title{
        top: 150px;
        padding: 0px 100px !important;
    }
  }

  @media (max-width:1023px){
    .footer::before {
        display: none;
      }
  }

  @media (max-width:768px){
    .img-inside {
        width: 15rem;
        height: 15rem;
      }
      .img-outside {
        width: 16rem;
        height: 16rem;
      }
    .services-icons{
        padding-top: 500px;
    }
    .services-title{
        top: -100px;
        height: 300px;
        width: 100%;
    }
    .services-icons{
        padding-top: 200px;
    }    
    section.services-title div.title{
        top: 100px;
        padding: 0px 50px !important;
    }
    .services{
        padding-bottom: 100px;
    }
    .map-view{
        width: 85vw;
        height: 85vw;
    }
  }

